import { Component, OnDestroy } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StoreSubscription } from 'src/lib';
import { State } from '../../reducers';
import { FINISH_REQUEST, START_REQUEST, UPDATE_REQUEST_PROGRESS } from '../../reducers/actions';
import { RequestSpinner } from '../../reducers/layout';
import { SpinnerService } from '../../services/spinner.service';
import { Store } from '../../services/store.service';

@UntilDestroy()
@Component({
  selector: 'roco-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
})
export class FrameComponent implements OnDestroy {
  title = 'Thalos';

  showSpinner: Observable<boolean>;
  spinner: Observable<RequestSpinner>;
  progressBar: Observable<number>;
  progress: number | null = null;

  private rId: string;

  storeSubscriptions: StoreSubscription<any>[] = [];

  public progressLabel: LabelSettings;

  constructor(
    private store: Store,
    router: Router,
    private spinnerService: SpinnerService,
  ) {
    router.events.pipe(untilDestroyed(this)).subscribe((ev) => {
      this.navigationInterceptor(ev as RouterEvent);
    });

    let showSpinner = this.store.subscribe((state: State) => state.layout.showSpinner, [START_REQUEST, FINISH_REQUEST]);
    let spinner = this.store.subscribe((state: State) => state.layout.currentSpinner, [START_REQUEST, FINISH_REQUEST]);
    let progressBar = this.store.subscribe((state: State) => (state.layout.currentSpinner ? state.layout.currentSpinner.progress : null), [START_REQUEST, FINISH_REQUEST, UPDATE_REQUEST_PROGRESS]);

    this.showSpinner = showSpinner.$.pipe(untilDestroyed(this));
    this.spinner = spinner.$.pipe(untilDestroyed(this));
    this.progressBar = progressBar.$.pipe(untilDestroyed(this));

    this.storeSubscriptions.push(showSpinner, spinner, progressBar);

    this.progressBar.subscribe((val) => {
      this.progress = val ?? null;
    });

    this.progressLabel = {
      visible: true,
      format: 'percent',
      position: 'end',
    };

    router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        take(1),
      )
      .subscribe((event: NavigationStart) => {
        if (event.url.includes('error/')) {
          router.navigate(['/']);
        }
      });
  }

  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      if (this.rId) {
        this.spinnerService.completeRequest(this.rId);
      }
      this.rId = this.spinnerService.startBackgroundRequest('Navigating');
    }

    if (event instanceof NavigationEnd) {
      this.spinnerService.completeRequest(this.rId);
      this.rId = null;
    }

    if (event instanceof NavigationCancel) {
      this.spinnerService.completeRequest(this.rId);
      this.rId = null;
    }

    if (event instanceof NavigationError) {
      this.spinnerService.completeRequest(this.rId);
      this.rId = null;
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.storeSubscriptions.forEach((s) => s.unsubscribe());
  }
}
