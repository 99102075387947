import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalInterceptor } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { CookieService } from 'ngx-cookie-service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

@UntilDestroy()
@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor, OnDestroy {
  static firstCall: boolean = true;
  private lastStatus: InteractionStatus = null;

  constructor(
    private msalInterceptor: MsalInterceptor,
    private spinnerService: SpinnerService,
    private router: Router,
    private cookieService: CookieService,
    private broadcastService: MsalBroadcastService,
  ) {
    this.broadcastService.inProgress$.pipe(untilDestroyed(this)).subscribe((is) => {
      if (this.lastStatus !== InteractionStatus.None) {
        this.lastStatus = is;
      }
    });
  }

  ngOnDestroy() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let rId: string;

    if (GlobalHttpInterceptor.firstCall === true) {
      rId = this.spinnerService.startRequest('Authenticating');
      GlobalHttpInterceptor.firstCall = false;
    }
    let waitForRedirect =
      this.lastStatus === InteractionStatus.None
        ? of(InteractionStatus.None)
        : this.broadcastService.inProgress$.pipe(
            filter((is) => is === InteractionStatus.None),
            take(1),
          );

    return waitForRedirect.pipe(
      switchMap(() => {
        return this.msalInterceptor.intercept(req, next);
      }),
      tap(() => {
        if (rId) {
          this.spinnerService.completeRequest(rId);
          rId = null;
        }
      }),
      catchError((error) => {
        console.log({ error });
        if (rId) this.spinnerService.completeRequest(rId);

        //TODO: Watch for that error coming up again
        if (error.errorCode) {
          let nav = this.router.getCurrentNavigation();
          if (!nav) {
            let url = window.location.pathname;
            sessionStorage.setItem('thalos-url-target', url);
          }
          if (error.errorCode === 'login_required' || error.errorCode === 'user_login_error') {
            this.cookieService.deleteAll('/');
            // this.router.navigate(['/login'],{ queryParams: { 'login': true, 'error': true } })
            return of(null);
          }
        }

        return throwError(error);
      }),
    );
  }
}
