import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuItem } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { YN } from 'src/lib/newBackendTypes';
import { GlobalSearchResult } from 'src/lib/newBackendTypes/globalSearch';
import { getIconClassFromEntityType } from 'src/lib/uiConstants';
import { SearchResult } from '../components/global-search/search-result.model';
import { EntityLookupService } from './entity-lookup.service';
import { Store } from './store.service';
import { ThalosApiService } from './thalos-api.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class GlobalSearchService {
  private dataSubject = new ReplaySubject<SearchResult[]>(1);
  results$: Observable<SearchResult[]> = this.dataSubject.asObservable();

  constructor(
    private api: ThalosApiService,
    private entityLookupService: EntityLookupService,
    private store: Store,
  ) {}

  // Gets a schema for generating a dynamic form. It is getting schemas from assets, buy they will come from the permissions.
  // The optional append parameter takes extra SearchResults that should be appended to the results
  search(query: string): Observable<GlobalSearchResultUI[]> {
    const menu = this.store.snapshot((state) => state.layout.flatMenu) ?? [];
    return this.api.rpc<GlobalSearchResult[]>(endpoints.globalSearch, { query }, []).pipe(
      map((results) => {
        return results.map((r) => {
          if (this.entityLookupService.getEntityExists(r.entityType)) {
            let link = this.entityLookupService.getLink(r.entityType);
            let icon = getIconClassFromEntityType(r.entityType) ?? 'fas fa-question';
            return {
              ...r,
              link,
              uuid: uuidv4(),
              icon,
            };
          }
          return r;
        });
      }),
      map((results): GlobalSearchResultUI[] => {
        if (query.length < 3) return results;
        let matchingMenuItems = menu
          .filter((item) => {
            return item.title.toLocaleLowerCase().includes(query.toLocaleLowerCase()) && !item.hidden && (!!item.link || !!item.effect);
          })
          .map((item) => ({
            ...item,
            primaryLabel: item.title,
            entityId: item.regexPath,
            secondaryLabel: '',
            archived: YN.N,
            uuid: uuidv4(),
          }));
        return [...matchingMenuItems, ...results];
      }),
    );
  }
}

export type GlobalSearchResultUI = Omit<GlobalSearchResult, 'entityId' | 'entityType'> & {
  entityId: any;
} & Partial<MenuItem>;
