import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MenuModule } from '@progress/kendo-angular-menu';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { CookieService } from 'ngx-cookie-service';
import { FlexComponent } from '../+modules/+flex/flex/containers/flex-view.component';
import { SharedModule } from '../shared/shared.module';
import { AppComponent } from './components/app/app.component';
import { ClockComponent } from './components/clock/clock.component';
import { DebugComponent } from './components/debug/debug.component';
import { ErrorComponent } from './components/error/error.component';
import { FooterComponent } from './components/footer/footer.component';
import { FrameComponent } from './components/frame/frame.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { HTMLDialogComponent } from './components/html-dialog/html-dialog.component';
import { LoginReturnComponent } from './components/login-return/login-return.component';
import { LoginComponent } from './components/login/login.component';
import { TextDialogComponent } from './components/text-dialog/text-dialog.component';
import { UserTasksComponent } from './components/user-tasks/user-tasks.component';
import { LoginReturnGuard } from './guards/login-return.guard';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';
import { reducers, REDUCERS_TOKEN } from './reducers';
import { EntityContainerResolver } from './resolvers/entity-container.resolver';
import { EntityLayoutsResolver } from './resolvers/entity-layouts.resolver';
import { EntityListResolver } from './resolvers/entity-list.resolver';
import { AuthGuard } from './services/authentication.guard';
import { AuthenticationService } from './services/authentication.service';
import { BusinessLogicService } from './services/business-logic-service';
import { CachingService } from './services/caching.service';
import { CommonDataService } from './services/common-data.service';
import { ConvertContractValidationService } from './services/convert-contract-validation.service';
import { DataCacheInterceptor } from './services/data-cache-interceptor';
import { DataFormattingService } from './services/data-formatting.service';
import { DelegateService } from './services/delegate-service.service';
import { FileUploadService } from './services/file-upload.service';
import { FlexService } from './services/flex.service';
import { GlobalSearchService } from './services/global-search.service';
import { ListFilterService } from './services/list-filter.service';
import { NotificationService } from './services/notification.service';
import { PromptService } from './services/prompt.service';
import { SelectorPopupService } from './services/selector-popup.service';
import { SharePointService } from './services/share-point-service.service';
import { SpinnerService } from './services/spinner.service';
import { Store } from './services/store.service';
import { ThalosApiService } from './services/thalos-api.service';
import { ValidatorService } from './services/validator.service';
import { WeightTicketService } from './services/weight-ticket.service';
import { SocketIOService } from './services/socket.io.service';
import { InvoiceNumberValidatorService } from './services/invoice-number-validator.service';

@NgModule({
  imports: [
    SharedModule,
    MenuModule,
    NotificationModule, // We're importing this module here and not in the Shared module because it's used by the Notification Service
  ],
  declarations: [
    FrameComponent,
    AppComponent,
    HeaderComponent,
    GlobalSearchComponent,
    FooterComponent,
    HomeComponent,
    ErrorComponent,
    LoginComponent,
    DebugComponent,
    LoginReturnComponent,
    ClockComponent,
    TextDialogComponent,
    UserTasksComponent,
    FlexComponent,
    HTMLDialogComponent,
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    SocketIOService,
    GlobalSearchService,
    NotificationService,
    CookieService,
    Store,
    CachingService,
    ListFilterService,
    ValidatorService,
    InvoiceNumberValidatorService,
    ConvertContractValidationService,
    ThalosApiService,
    WeightTicketService,
    SpinnerService,
    FileUploadService,
    CommonDataService,
    BusinessLogicService,
    PromptService,
    DataFormattingService,
    DataFormattingService,
    SelectorPopupService,
    FlexService,
    DelegateService,
    SharePointService,
    {
      provide: REDUCERS_TOKEN,
      useValue: reducers,
    },
    EntityContainerResolver,
    EntityListResolver,
    EntityLayoutsResolver,
    UnsavedChangesGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataCacheInterceptor,
      multi: true,
    },
    LoginReturnGuard,
  ],
})
export class CoreModule {}
