import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StoreSubscription } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { endpointAuthorizationSubscription, endpointsAuthorized } from 'src/lib/helperFunctions';
import { UtilsService } from 'src/lib/layoutsUtils';
import { Contact, fullNameFromContact } from 'src/lib/newBackendTypes';
import { NotificationActivatedIcon } from 'src/lib/uiConstants';
import { MenuItem } from '../../../../lib/MenuItem';
import { State } from '../../reducers';
import { CALCULATED_DYNAMIC_MENUS, LOGIN, SET_USER } from '../../reducers/actions';
import { AuthenticationService } from '../../services/authentication.service';
import { EntityVersionEvent, SocketIOService } from '../../services/socket.io.service';
import { Store } from '../../services/store.service';
import { ListNotifications } from 'src/lib/newBackendTypes/notificationTypes/listNotifications';

@UntilDestroy()
@Component({
  selector: 'roco-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  production = environment.productionHeader;
  notificationActivatedIcon = NotificationActivatedIcon;
  expandNotificationCenter: boolean;
  expandSidebar: boolean;

  get globalSearchAuthorized() {
    return this.authorized[endpoints.globalSearch];
  }

  get notificationsAuthorized() {
    return this.authorized[endpoints.listNotifications];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  @Input()
  entityVersionEvent: EntityVersionEvent;

  permittedMenu: Observable<readonly MenuItem[]>;
  innerWidth: number;
  username: Observable<string>;
  storeSubscriptions: StoreSubscription<any>[] = [];
  authorized: endpointsAuthorized;

  user: Contact;
  menuType = MenuType;
  notifications: ListNotifications[];
  notificationsNotReadCount: number | null = null;
  allNotificationsCounter: number | null = null;
  isAllNotificationsCountGreater: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private store: Store,
    private router: Router,
    utils: UtilsService,
    private socketIOService: SocketIOService,
  ) {
    this.expandSidebar = false;
    this.expandNotificationCenter = false;

    this.user = this.store.snapshot((state) => state.user.user);
    const permittedMenu = this.store.subscribe((state: State) => state.layout.permittedMenu, [CALCULATED_DYNAMIC_MENUS]);
    const username = this.store.subscribe(
      (state: State) => {
        if (!state.user.user) return '';
        const name = fullNameFromContact(state.user.user);
        return name;
      },
      [SET_USER, LOGIN],
    );

    this.permittedMenu = permittedMenu.$.pipe(untilDestroyed(this));
    this.username = username.$;

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.expandNotificationCenter = false;
        this.expandSidebar = false;
      }
    });

    this.storeSubscriptions.push(permittedMenu, username);

    utils.$emitterToggleSideBar.subscribe(() => {
      this.toggleSidebar(MenuType.MENU);
    });

    endpointAuthorizationSubscription(store, this);
    if (this.notificationsAuthorized) this.loadNotificationsFromDB();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    this.socketIOService.connect(this.user, this.entityVersionEvent);

    this.socketIOService.notificationsInfo.subscribe((res) => {
      if (res) {
        this.notifications = [...res.notifications];
        this.notificationsNotReadCount = res.notificationsNotReadCount;
      }
    });
  }

  ngOnDestroy(): void {
    this.storeSubscriptions.forEach((s) => s.unsubscribe());
    this.socketIOService.disconnect(this.user);
  }

  loadNotificationsFromDB() {
    this.socketIOService.notificationsFromDB(this.user).then((res) => {
      if (res) {
        this.notifications = [...res.notifications];
        this.notificationsNotReadCount = res.notificationsNotReadCount;
        this.allNotificationsCounter = res.allNotificationsCounter;
        this.isAllNotificationsCountGreater = this.allNotificationsCounter > this.notifications.length;
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  toggleSidebar(menuType: MenuType) {
    if (menuType === MenuType.MENU) {
      this.expandNotificationCenter = false;
      this.expandSidebar = !this.expandSidebar;
    } else {
      this.expandSidebar = false;
      this.expandNotificationCenter = !this.expandNotificationCenter;
    }
  }
}

export enum MenuType {
  MENU = 'Menu',
  NOTIFICATIONS = 'Notifications',
}
